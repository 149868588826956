import { render, staticRenderFns } from "./PreviewFormulaire.vue?vue&type=template&id=2f6a810b&scoped=true&"
import script from "./PreviewFormulaire.vue?vue&type=script&lang=js&"
export * from "./PreviewFormulaire.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6a810b",
  null
  
)

export default component.exports