<template>
  <div>
    <!-- Table Container Card -->
    <div v-if="componentFormExist">
      <component :is="selectedForm.vueComponents.preview" />
    </div>

    <div v-if="!componentFormExist">
      <h1>Ce formualaire est indisponible pour le moment</h1>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'

import utilsService from '@/services/utils/utils.service'

// all statsform are imported here
const PreviewEntreesFrontieresTerrestres = () => import ('@/views/apps/formulaires/frontieres-terrestres-entrees/PreviewEntreesFrontieresTerrestres.vue')
const PreviewSortiesFrontieresTerrestres = () => import ('@/views/apps/formulaires/frontieres-terrestres-sorties/PreviewSortiesFrontieresTerrestres.vue')
const PreviewAgenceVoyage = () => import ('./agence-voyage/PreviewAgenceVoyage.vue')
const PreviewAgenceTransport = () => import ('./agence-transport/PreviewAgenceTransport.vue')
const PreviewAgenceSportive = () => import ('./agence-sportive/PreviewAgenceSportive.vue')
const PreviewAgenceCulturelle = () => import ('./agence-culturelle/PreviewAgenceCulturelle.vue')
const PreviewLocation = () => import ('./location/PreviewLocation.vue')
const PreviewRestauration = () => import ('./restauration/PreviewRestauration.vue')
const PreviewHotel = () => import ('./hotel/PreviewHotel.vue')
const PreviewHotelClient = () => import ('./ficheEnregistrementClient/hotel/PreviewHotelClient.vue')
const PreviewEntreeAeroport = () => import ('./ficheEnregistrementClient/entree-aeroport/PreviewEntreeAeroport.vue')
const PreviewSortieAeroport = () => import ('./ficheEnregistrementClient/sortie-aeroport/PreviewSortieAeroport.vue')
const PreviewFicheTourismeInterne = () => import ('./tourisme-interne/PreviewFicheTourismeInterne.vue')
const PreviewFicheTourismeEmetteur = () => import ('./tourisme-emetteur/PreviewFicheTourismeEmetteur.vue')
const PreviewFicheTourismeRecepteur = () => import ('./tourisme-recepteur/PreviewFicheTourismeRecepteur.vue')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,

    // form preview
    PreviewAgenceVoyage,
    PreviewLocation,
    PreviewAgenceTransport,
    PreviewAgenceSportive,
    PreviewAgenceCulturelle,
    PreviewRestauration,
    PreviewHotel,
    PreviewHotelClient,
    PreviewEntreesFrontieresTerrestres,
    PreviewEntreeAeroport,
    PreviewSortieAeroport,
    PreviewFicheTourismeInterne,
    PreviewSortiesFrontieresTerrestres,
    PreviewFicheTourismeEmetteur,
    PreviewFicheTourismeRecepteur,
  },
  data() {
    return {
      selectedForm: null,
      previewData: null,
    }
  },
  computed: {
    componentFormExist() {
      return this.selectedForm && this.selectedForm.vueComponents
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { slug, data, model } = val.params
        this.previewData = data
        if (!slug) {
          this.selectedForm = utilsService.getFormulaireByModel(model)
          return
        }
        this.selectedForm = utilsService.getFormulaireBySlug(slug)
      },
    },
  },

  mounted() {},
}
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
